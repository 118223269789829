import React from "react";
import {createRoot} from "react-dom/client";
import App from "./components/App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");

if (rootElement) {
    const root = createRoot(rootElement);

    root.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    );
}

serviceWorkerRegistration.register();
reportWebVitals();
