import {useState} from "react";
import {Box, Button, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {Menu as MenuIcon, MenuOpen} from "@mui/icons-material";

interface IMenu {
    onOpenTutorial: () => void
}
export function Menu({onOpenTutorial}: IMenu) {
    const [open, setOpen] = useState(false);
    const styles = {
        content: {
            position: "absolute",
            right: 10,
            top: 10
        }
    };
    const handleDialogClose = () => setOpen(false);
    const handleMenuOpen = () => setOpen(true);
    const handleOpenTutorial = () => {
        setOpen(false);
        onOpenTutorial();
    }

    return (
        <Box sx={styles.content}>
            <IconButton onClick={handleMenuOpen}>
                {open ? <MenuOpen/> : <MenuIcon/>}
            </IconButton>
            <Dialog fullWidth open={open} onClose={handleDialogClose}>
                <DialogTitle>
                    There in Five
                </DialogTitle>
                <DialogContent>
                    <Button onClick={handleOpenTutorial}>Tutorial</Button>
                </DialogContent>
            </Dialog>
        </Box>
    );
}