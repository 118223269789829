import {Box} from "@mui/material";
import React, {} from "react";
import {Cell} from "./Cell";

interface IRow {
    from?: boolean
    onSelect?: (offset: number) => void
    selected?: number
    step?: boolean
    to?: boolean
    word: string
}

export function Row({word, onSelect, selected, from = false, step = false, to = false}: IRow) {
    const styles = {
        row: {
            display: "flex"
        }
    };

    return (
        <Box sx={styles.row}>
            {Array.from(word).map((character, index) =>
                <Cell key={index} character={character} from={from} offset={index} onSelect={onSelect}
                      selected={selected} step={step} to={to}/>
            )}
        </Box>
    );
}
