import data from "../data/words.json";
import {Box, Typography} from "@mui/material";
import {Game} from "../game/game";
import React, {useCallback, useEffect, useState} from "react";
import {Row} from "./Row";
import {Keyboard} from "./Keyboard";
import {replaceAt} from "../utility/string";
import {Complete} from "./Complete";
import {Problem} from "../game/problem";
import {Menu} from "./Menu";
import {Tutorial} from "./Tutorial";

export function ThereInFive() {
    const [selected, setSelected] = useState<number>();
    const [legal, setLegal] = useState(false);
    const [problem, setProblem] = useState<Problem>();
    const [tutorialOpen, setTutorialOpen] = useState(false);
    const [working, setWorking] = useState("");
    const [workingScore, setWorkingScore] = useState(0);
    const styles = {
        title: {
            marginTop: 4
        },
        grid: {
            display: "flex",
            flexDirection: "column",
            margin: 2
        },
        page: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        score: {
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%"
        }
    };
    const handleCharacter = useCallback((character: string) => {
        if (selected !== undefined) {
            if (problem?.setWorking(replaceAt(working, selected, character))) {
                setLegal(problem?.getIsLegal());
                setWorking(problem.working);
                setWorkingScore(problem.workingScore);
            }
        }
    }, [problem, selected, working]);
    const handleConfirm = useCallback(() => {
        if (problem?.commit()) {
            setLegal(problem.getIsLegal());
            setSelected(undefined);
            setWorkingScore(problem.workingScore);
        }
    }, [problem]);
    const handleSelect = useCallback((index: number) => {
        setSelected(index);
    }, []);
    const handleUndo = useCallback(() => {
        if (problem) {
            problem.undo();
            setLegal(problem.getIsLegal());
            setSelected(undefined);
            setWorking(problem.working);
            setWorkingScore(problem.workingScore);
        }
    }, [problem]);
    const handleSetTutorialOpen = (open: boolean) => setTutorialOpen(open);

    useEffect(() => {
        if (problem === undefined) {
            const date = new Date(Date.now());
            const game = new Game(data.final, data.valid);
            const seed = date.getFullYear() * 10000 + date.getMonth() * 100 + date.getDate();
            const problem = game.getProblem(seed);

            setProblem(problem);
            setWorking(problem.working);
            setWorkingScore(problem.workingScore);
        }
    }, [problem]);
    useEffect(() => {
        setTutorialOpen(localStorage.getItem("tutorialOpenAtStart") !== "false");
    }, []);
    
    if (problem === undefined) return null;
    return (
        <Box sx={styles.page}>
            <Tutorial open={tutorialOpen} onSetOpen={handleSetTutorialOpen}/>
            <Menu onOpenTutorial={() => handleSetTutorialOpen(true)}/>
            <Complete problem={problem}/>
            <Typography variant="h4" sx={styles.title}>There in Five</Typography>
            <Box sx={styles.grid}>
                <Row from={true} word={problem.from}/>
                {problem.steps.map((step, index) => {
                    const word = index === problem.step ? working : step;

                    return <Row key={index} onSelect={handleSelect}
                                selected={index === problem.step ? selected : undefined} step={index === problem.step}
                                word={word}/>
                })}
                <Row to={true} word={problem.to}/>
            </Box>
            <Box sx={styles.score}>
                <Typography component="span" variant="h5">{`Score ${workingScore}`}</Typography>
                <Typography component="span" variant="h5">{`Letter ${problem.nextLetterCost}`}</Typography>
            </Box>
            <Keyboard undoable={problem.undoable} legal={legal} onConfirm={handleConfirm}
                      onCharacter={handleCharacter} onUndo={handleUndo}/>
        </Box>
    );
}