import {Box, Dialog, Typography} from "@mui/material";
import {Problem} from "../game/problem";
import React from "react";
import {RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton}
    from "react-share";

interface IComplete {
    problem: Problem
}
export function Complete({problem}: IComplete) {
    const styles = {
        content: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        score: {
            margin: 2,
            textAlign: "center"
        },
        share: {
            alignItems: "center",
            display: "flex",
            justifyContent: "space-evenly",
            margin: 2
        },
        title: {
            margin: 4
        }
    }
    const share = `I got from ${problem.from} to ${problem.to} in five with a score of ${problem.workingScore}`;

    return (
        <Dialog open={problem.complete}>
            <Box sx={styles.content}>
                <Typography variant="h4" sx={styles.title}>You Won!</Typography>
                <Typography variant="h6" sx={styles.score}>{`Score: ${problem.workingScore}`}</Typography>
                <Box sx={styles.share}>
                    <Typography>Share: </Typography>
                    <RedditShareButton title={share} url="https://thereinfive.dev/">
                        <RedditIcon size={24}/>
                    </RedditShareButton>
                    <TwitterShareButton title={share} url="https://thereinfive.dev/">
                        <TwitterIcon size={24}/>
                    </TwitterShareButton>
                    <WhatsappShareButton title={share} url="https://thereinfive.dev/">
                        <WhatsappIcon size={24}/>
                    </WhatsappShareButton>
                </Box>
            </Box>
        </Dialog>
    );
}