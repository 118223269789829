export class Random {
    constructor(private _seed: number = 0) {
    }

    get seed() {
        return this._seed;
    }

    // Mulberry32
    get(max: number = 1) {
        let number = this._seed += 0x6D2B79F5;

        number = Math.imul(number ^ (number >>> 15), number | 1);
        number ^= number + Math.imul(number ^ (number >>> 7), number | 61);
        return max * ((number ^ (number >>> 14)) >>> 0) / 4294967296;
    }
    
    getInt(max: number) {
        return Math.floor(this.get(max));
    }
    
    shuffle<Type>(deck: Type[]) {
        return deck.sort((lhs, rhs) => this.get() - 0.5);
    }
}