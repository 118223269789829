import {Box, Typography, useTheme} from "@mui/material";

interface ICell {
    character: string
    from: boolean
    offset: number
    onSelect?: (offset: number) => void
    selected?: number;
    step: boolean
    to: boolean
}

export function Cell({character, from, offset, onSelect, selected, step, to}: ICell) {
    const theme = useTheme();
    const fontSize = 24;
    const size = 50;
    const styles = {
        cell: {
            alignItems: "center",
            backgroundColor: offset === selected ? theme.palette.success.main :
                (step || to || from ? theme.palette.background.paper : theme.palette.grey[300]),
            borderColor: from || to ? theme.palette.background.paper : theme.palette.grey[500],
            borderStyle: "solid",
            borderWidth: step ? 2 : 1,
            display: "flex",
            height: size,
            justifyContent: "center",
            margin: step ? "7px" : "8px",
            width: size
        },
        text: {
            fontSize,
            fontWeight: "bold",
            textTransform: "capitalize"
        }
    };
    const handleClick = () => {
        onSelect && onSelect(offset);
    };

    return (
        <Box onClick={handleClick} sx={styles.cell}>
            <Typography sx={styles.text}>{character}</Typography>
        </Box>
    );
}
