import React from 'react';
import {ThereInFive} from "./ThereInFive";

function App() {
    return (
        <ThereInFive/>
    );
}

export default App;
