import React from "react";
import {Box, Typography, useTheme} from "@mui/material";
import {Undo} from "@mui/icons-material";

interface IKeyboard
{
    undoable: boolean
    legal: boolean
    onCharacter: (character: string) => void
    onConfirm: () => void
    onUndo: () => void
}

export function Keyboard({undoable, legal, onCharacter, onConfirm, onUndo}: IKeyboard) {
    const keys = [
        ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
        ["a", "s", "d", "f", "g", "h", "j", "k", "l", "undo"],
        ["z", "x", "c", "v", "b", "n", "m", "confirm"]
    ];
    const theme = useTheme();
    const styles = {
        confirm: {
            backgroundColor: legal ? theme.palette.success.light : theme.palette.grey[100],
            paddingLeft: 1,
            paddingRight: 1,
            width: "auto"
        },
        key: {
            alignItems: "center",
            borderColor: theme.palette.grey[500],
            borderStyle: "solid",
            borderWidth: 1,
            display: "flex",
            height: 36,
            justifyContent: "center",
            margin: 0.5,
            width: 26
        },
        keyboard: {
            display: "flex",
            flexDirection: "column",
            margin: 1,
            marginTop: 3
        },
        row: {
            display: "flex",
            justifyContent: "center"
        },
        text: {
            textTransform: "capitalize"
        },
        undo: {
            backgroundColor: undoable ? theme.palette.warning.light : theme.palette.grey[100],
            paddingLeft: 1,
            paddingRight: 1,
            width: "auto"
        }
    };

    return (
        <Box sx={styles.keyboard}>
            {keys.map((row, index) =>
                <Box key={index} sx={styles.row}>
                    {row.map((key, index) => {
                        if (key === "confirm") {
                            return (
                                <Box key={index} onClick={onConfirm} sx={{...styles.key, ...styles.confirm}}>
                                    <Typography sx={styles.text}>{key}</Typography>
                                </Box>
                            );
                        } else if (key === "undo") {
                            return (
                                <Box key={index} onClick={onUndo} sx={{...styles.key, ...styles.undo}}>
                                    <Undo/>
                                </Box>
                            );
                        } else {
                            return (
                                <Box key={index} onClick={() => onCharacter(key)} sx={styles.key}>
                                    <Typography sx={styles.text}>{key}</Typography>
                                </Box>
                            );
                        }
                    })}
                </Box>
            )}
        </Box>
    );
}