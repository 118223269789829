import {Game} from "./game";

export class Problem {
    private static Blank = " ".repeat(5);

    private readonly _steps: string[];

    private _score: number;
    private _step: number;
    private _working: string;
    private _workingScore: number

    constructor(private _from: string, private _to: string, private _valid: Set<string>) {
        this._step = 0;
        this._steps = [_from, ...new Array(4).fill(Problem.Blank)];
        this._working = _from;
        this._score = Game.InitialScore;
        this._workingScore = this._score;
    }

    get undoable() {
        return Boolean(this.step) || this._working !== this._steps[this._step];
    }

    get complete() {
        return this._step === this._steps.length;
    }

    get from() {
        return this._from;
    }

    get currentStep() {
        return this._steps[this._step];
    }

    getIsLegal() {
        return this.step === this._steps.length - 1 ? this._working === this._to :
            (this._valid.has(this._working) && ! this.steps.includes(this._working) &&
                this._working !== this._to && this._working !== this.from);
    }

    get nextLetterCost() {
        if (this._step < this._steps.length) {
            const difference = this.getDifference(this._working);

            if (difference < Game.SwapCost.length) {
                return Game.LetterCost[difference];
            }
        }
        return 0;
    }
    
    get step() {
        return this._step;
    }

    get steps() {
        return this._steps;
    }

    get to() {
        return this._to;
    }

    get working() {
        return this._working;
    }

    get workingScore() {
        return this._workingScore;
    }

    commit() {
        if (this.getIsLegal()) {
            this._workingScore += Game.StepScore
            this._score = this._workingScore;
            this._steps[this._step] = this._working;
            this._step += 1;
            if (this._step < this._steps.length) {
                this._steps[this._step] = this._working;
            }
            return true;
        }
        return false;
    }

    setWorking(word: string) {
        const difference = this.getDifference(word);

        if (difference === 0) {
            this._working = word;
            this._workingScore = this._score;
            return true;
        } else if (difference <= Game.SwapCost.length && this._score >= Game.SwapCost[difference - 1]) {
            this._working = word;
            this._workingScore = this._score - Game.SwapCost[difference - 1];
            return true;
        }
        return false;
    }
    
    undo() {
        if (this.undoable) {
            if (this._working !== this._steps[this.step]) {
                this.setWorking(this._steps[this.step]);
            } else if (this.step > 0) {
                const working = this._steps[this.step - 1];

                this._steps[this.step] = Problem.Blank;
                --this._step;
                if (this._step === 0) {
                    this._steps[this._step] = this.from;
                } else {
                    this._steps[this._step] = this._steps[this._step - 1];
                }

                const difference = this.getDifference(working);

                this._score = this._score - Game.StepScore + Game.SwapCost[difference - 1];
                this.setWorking(working);
            }
        }
    }
    
    private getDifference(word: string) {
        return Array.from(word).reduce((different, character, index) =>
            different + (character !== this.currentStep[index] ? 1 : 0), 0);
    }
}