import {Box, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import {ChangeEventHandler, useState} from "react";

interface ITutorial {
    open: boolean
    onSetOpen: (open: boolean) => void
}

export function Tutorial({open, onSetOpen}: ITutorial) {
    const [openAtStart, setOpenAtStart] = useState(localStorage.getItem("tutorialOpenAtStart") !== "false")
    const styles = {
        text: {
            marginBottom: 1
        },
        title: {
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between"
        }
    }
    const handleClose = () => onSetOpen(false);
    const handleOpenAtStartChange: ChangeEventHandler<HTMLInputElement> = ({target}) => {
        if (target.checked) {
            localStorage.setItem("tutorialOpenAtStart", "true");
        } else {
            localStorage.setItem("tutorialOpenAtStart", "false");
        }
        setOpenAtStart(target.checked);
    }
    
    return (
        <Dialog fullWidth open={open} onClose={handleClose}>
            <Box sx={styles.title}>
                <DialogTitle>
                    There in Five
                </DialogTitle>
                <IconButton onClick={handleClose}><Close/></IconButton>
            </Box>
            <DialogContent>
                <DialogContentText sx={styles.text}>
                    Welcome to There in Five, a word game that challenges you to find a route from one word to another,
                    chaining together exactly five words that differ by one two or three letters. At each step you
                    can spend your points to change letters, the first costs a single point, the second two more and a
                    third letter costs another three points. At each step you will gain five more points.
                </DialogContentText>
                <DialogContentText sx={styles.text}>
                    For example, you can change <em>THERE</em> to <em>WHERE</em> by changing one letter for a
                    total cost of 1. Next you could change <em>WHERE</em> to <em>WHALE</em> by changing two letters
                    for a total cost of 3 (1 + 2). Whatever route you take, you must use five words to get to your
                    destination no fewer and no more!
                </DialogContentText>
                <DialogContentText sx={styles.text}>
                    Good luck!
                </DialogContentText>
                <Box sx={styles.title}>
                    <DialogContentText>Show tutorial at startup</DialogContentText>
                    <Checkbox checked={openAtStart} onChange={handleOpenAtStartChange}/>
                </Box>
            </DialogContent>
        </Dialog>
    );
}