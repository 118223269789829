import {Random} from "../utility/random";
import {replaceAt} from "../utility/string";
import {Problem} from "./problem";

export const ProblemLength = 5;

interface IDistribution {
    probability: number
    swaps: number
}

interface ISolution {
    head: string
    score: number
    steps: string[]
}

export class Game {

    static InitialScore = 5;
    static LetterCost = [1, 2, 3];
    static StepScore = 5;
    static SwapCost = [1, 3, 6];

    private readonly _final: Set<string>;
    private readonly _valid: Set<string>;
    private readonly _characters: string[];

    private _swapDist: IDistribution[] = [
        {probability: 0.7, swaps: 1},
        {probability: 0.2, swaps: 2},
        {probability: 0.1, swaps: 3}
    ];

    constructor(final: string[], valid: string[]) {
        this._characters = new Array(26).fill("a".charCodeAt(0))
            .map((start, index) => String.fromCharCode(start + index));
        this._final = new Set(final);
        this._valid = new Set(valid);
    }
    
    getProblem(seed: number): Problem {
        const random = new Random(seed);

        while(true) {
            try {
                const solution = this.buildSolution(random);

                return new Problem(solution.steps[0], solution.steps[ProblemLength - 1], this._valid);
            }
            catch(error) {
                console.error(error);
            }
        }
    }

    buildSolution(random: Random) {
        const head = Array.from(this._final.values())[random.getInt(this._final.size)];
        const solution: ISolution = {
            head,
            score: Game.InitialScore,
            steps: [head]
        };

        while (solution.steps.length < ProblemLength) {
            if (! this.extendSolution(random, solution)) {
                throw new Error(`Could not find a next step within score from ${solution.steps.join(", ")}`);
            }
        }
        return solution;
    }

    private extendSolution(random: Random, solution: ISolution) {
        return this.getSwaps(random, solution.score).some(distribution => {
            const word = this.findWord(random, distribution.swaps, solution.head, solution.steps);

            if (word) {
                solution.score -= Game.SwapCost[distribution.swaps - 1];
                solution.score += ProblemLength;
                solution.steps.push(word);
                return true;
            }
            return false;
        });
    }
    
    private chooseSomeFromAll<Type>(all: Type[], choose: number, result: Type[][] = [], some: Type[] = []) {
        if (choose === 0) {
            result.push(some);
        } else {
            all.forEach(choice => {
                if (! some.includes(choice)) {
                    this.chooseSomeFromAll(all, choose - 1, result, [...some, choice]);
                }
            })
        }
        return result;
    }

    private findWord(random: Random, swaps: number, start: string, steps: string[]) {
        const replace = (word: string, characters: string[], indices: number[]) => {
            characters.forEach((character, index) =>
                word = replaceAt(word, indices[index], character));
            return word;
        }
        const indices = new Array(ProblemLength).fill(0).map((_, index) => index);
        const replacements = this.chooseSomeFromAll(random.shuffle(indices), swaps);
        const letters = this.chooseSomeFromAll(random.shuffle(this._characters), swaps);
        let word;

        replacements.some(replacement => letters.some(letters => {
            const candidate = replace(start, letters, replacement);

            if (! steps.includes(candidate) && (this._final.has(candidate) ||
                    (steps.length < ProblemLength - 1 && this._valid.has(candidate)))
            ) {
                word = candidate;
                return true;
            }
            return false;
        }));
        return word;
    }

    private getSwaps(random: Random, score: number) {
        if (score < Game.SwapCost[0]) {
            throw new Error("Insufficient score to continue");
        }

        return this._swapDist.filter(swap => Game.SwapCost[swap.swaps - 1] <= score)
            .sort((lhs, rhs) =>
                lhs.probability + random.get() - rhs.probability + random.get()
            );
    }
}